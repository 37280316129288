<template>
  <div id="app">
    <div class="header">
      <el-page-header @back="goBack"></el-page-header>
      <div class="div-total" v-show="questionRecords.length>0" v-html="totalHtml"></div>
    </div>
    <div style="clear:both;padding: 50px 10px;">
      <div :class="questionRecord.id == currentId ? 'div-current' : 'div-normal' " v-for="(questionRecord,i) in questionRecords">
         <el-button type="primary" v-show="questionRecord.result" @click="btnClick(questionRecord)">{{i+1}}</el-button>
        <el-button type="danger"  v-show="!questionRecord.result" @click="btnClick(questionRecord)">{{i+1}}</el-button>
      </div>
    </div>
    <div style="clear: both"></div>
    <question-record-k-g v-if="showQuestionRecordKG" ref="questionRecordKG" style="margin:0px 15px"></question-record-k-g>
    <question-record-z-g v-if="showQuestionRecordZG" ref="questionRecordZG" style="margin:0px 15px"></question-record-z-g>
  </div>
</template>
<script>
import {UrlEnum,request,QuestionTypeEnum} from "../../../../public/js/common-vue";
import QuestionRecordKG from "@/components/QuestionRecordKG";
import QuestionRecordZG from "@/components/QuestionRecordZG";

export default {
  components: {QuestionRecordZG, QuestionRecordKG},
  data(){
    return{
      questionRecords:[],
      currentId:0,
      showQuestionRecordKG:false,
      showQuestionRecordZG:false,
      totalHtml:''
    }
  },
  methods: {
    goBack() {
      this.$router.back(-1);
    },
    btnClick(questionRecord){
      let id = questionRecord.id;
      let questionType = questionRecord.questionType;
      if(questionType == QuestionTypeEnum.BU_DING_XIANG || questionType == QuestionTypeEnum.DUO_XUAN || questionType == QuestionTypeEnum.DAN_XUAN){
        this.$refs.questionRecordKG.initData(id);
      }
      else{
        this.$refs.questionRecordZG.initData(id);
      }
    },
    initData() {
      const url = UrlEnum.STUDENT_QUESTION_RECORDS + "?examId=" + request("examId") + "&papge=1&size=1000";
      this.$http({
        method: "get",
        url:  url,
        data: '',
      })
      .then((res) => {
        this.questionRecords = res.data.list;
        if(this.questionRecords.length > 0){
          let questionType = this.questionRecords[0].questionType;
          if(questionType == QuestionTypeEnum.BU_DING_XIANG || questionType == QuestionTypeEnum.DUO_XUAN || questionType == QuestionTypeEnum.DAN_XUAN){
            this.showQuestionRecordKG = true;
            this.makeKGTotal();
          }
          else{
            this.showQuestionRecordZG = true;
            this.makeZGTotal();
          }
        }

      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    },
    makeKGTotal(){
      let rightNum=0,wrongNum=0,accuracy;
      for(let i=0;i<this.questionRecords.length;i++){
        let questionRecord = this.questionRecords[i];
        if(questionRecord.result){
          rightNum ++;
        }
        else{
          wrongNum ++;
        }
      }
      accuracy = Math.round(rightNum / (rightNum + wrongNum) * 100);
      this.totalHtml='正确：'+rightNum+'&nbsp;&nbsp;&nbsp;&nbsp;错误：'+wrongNum+'&nbsp;&nbsp;&nbsp;&nbsp;正确率：'+accuracy+'%'
    },
    makeZGTotal(){
      let totalScore=0,myScore=0,accuracy=0;
      for(let i=0;i<this.questionRecords.length;i++){
        totalScore += this.questionRecords[i].totalScore;
        myScore += this.questionRecords[i].myScore;
      }
      if(totalScore != 0){ //排除总分为零的情况
        accuracy = myScore / totalScore * 100;
      }
      this.totalHtml='总分：'+totalScore+'&nbsp;&nbsp;&nbsp;&nbsp;得分：'+myScore+'&nbsp;&nbsp;&nbsp;&nbsp;正确率：'+accuracy+'%'
    }
  },
  mounted() {
    this.initData();
  }
}
</script>

<style scoped>
.el-button{
  padding: 10px !important;
  width:40px !important;
  margin-left: 0px !important;
}

.div-normal{
  float: left;
  margin: 6px;
  box-sizing: border-box;
  width: 44px;
  height: 40px;
  border-radius: 6px;
  border:2px solid #ffffff;
}
.div-current{
  float: left;
  margin: 6px;
  box-sizing: border-box;
  width: 44px;
  height: 40px;
  border-radius: 6px;
  border:2px solid #DD691D;
}
.header{
  height: 45px;
  width: calc(100% - 30px);
  background-color: #ffffff;
  position: absolute;
}
.el-page-header {
  display: flex;
  line-height: 45px;
  margin-left: 20px;
  float: left;
}
.el-page-header__left {
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  position: absolute;
  width: 100%;
  height: 45px !important;
  line-height: 45px !important;
}
.div-total{
  position: relative;
  height: 45px;
  line-height: 45px;
  left: 10px;
  color: #3F3F3F;
  float: left;
}
/*html,body{*/
/*  overflow-x: auto;*/
/*}*/
#app{
  height: calc(100% - 15px);
  overflow: auto;
}
</style>
