<template>
  <div v-show="questionRecord.id != null">
  <el-card class="box-card">
    <h5>记录ID:{{ questionRecord.id }}</h5>
    <h5>学员ID:{{ questionRecord.studentId }}</h5>
    <h5>题目ID:{{ questionRecord.questionId }}</h5>
    <h5>题型:{{ questionRecord.questionTypeName }}</h5>
    <h5>来源: {{ questionRecord.sourceName }}</h5>
    <h5>答题状态:{{ questionRecord.isCompleted == true ? "已交卷" : "作答中" }}</h5>
    <h5>总分:{{questionRecord.totalScore}}</h5>
    <h5>我的得分：{{ questionRecord.myScore }}</h5>
  </el-card>
  <el-card class="box-card">
    <h5>材料</h5>
    <p>{{ questionRecord.stem }}</p>
  </el-card>
  <el-card class="box-card">
    <el-tabs v-model="activeName">
      <el-tab-pane
          :name="'tab_'+i"
          v-for="(v, i) in questionRecord.children"
          :key="i"
          :label="'问题' + (i+1)"
      >
        <h5>题干</h5>
        <p v-html="questionRecord.children[i].stem"></p>
        <h5>答案</h5>
        <p v-html="questionRecord.children[i].answer"></p>
        <h5>我的回答</h5>
        <p style="white-space: pre-wrap;text-indent:24px" v-html="questionRecord.children[i].myAnswer"></p>
        <h5>得分词</h5>
        <p v-for="(scoreWord, j) in questionRecord.children[i].scoreWords" :key="j">
          <span v-if="questionRecord.children[i].scoreWords[j].myScore==0" class="word-zero">{{ questionRecord.children[i].scoreWords[j].word }} ({{questionRecord.children[i].scoreWords[j].myScore}}/{{questionRecord.children[i].scoreWords[j].score}}分)</span>
          <span v-else-if="questionRecord.children[i].scoreWords[j].myScore==questionRecord.children[i].scoreWords[j].score" class="word-full">{{ questionRecord.children[i].scoreWords[j].word }} ({{questionRecord.children[i].scoreWords[j].myScore}}/{{questionRecord.children[i].scoreWords[j].score}}分)</span>
          <span v-else  class="word-similar">{{ questionRecord.children[i].scoreWords[j].word }} ({{questionRecord.children[i].scoreWords[j].myScore}}/{{questionRecord.children[i].scoreWords[j].score}}分)</span>
        </p>
        <h5>文字解析</h5>
        <p style="white-space: pre-wrap" v-html="questionRecord.children[i].analysisText"></p>
      </el-tab-pane>
    </el-tabs>
  </el-card>
  </div>
</template>

<script>
import {UrlEnum,request,QuestionTypeEnum} from "../../public/js/common-vue";

export default {
  name: "QuestionRecordZG",
  data(){
    return{
      activeName: "tab_0",
      questionRecord: {},
    }
  },
  methods:{
    initData(id) {
      this.$http({
        method: "get",
        url: UrlEnum.STUDENT_QUESTION_RECORDS  + "/" + id,
      }).then((res) => {
        console.log(res);
        this.questionRecord = res.data;
        //设置段落
        if(this.questionRecord.questionType == QuestionTypeEnum.JIAN_DA || this.questionRecord.questionType == QuestionTypeEnum.LUN_SHU){
          this.questionRecord.children = [{stem:'',answer:'',myAnswer:''}];
          this.questionRecord.children[0].stem = this.questionRecord.stem;
          this.questionRecord.children[0].answer = this.questionRecord.answer;
          this.questionRecord.children[0].scoreWords = this.questionRecord.scoreWords;
          this.questionRecord.children[0].analysisText = this.questionRecord.analysisText;
          this.questionRecord.children[0].myAnswer = "<p>"+this.questionRecord.myAnswer;
          this.questionRecord.children[0].myAnswer = this.questionRecord.myAnswer.replaceAll(" ",""); //手工敲的空格取消
          this.questionRecord.children[0].myAnswer = this.questionRecord.myAnswer.replace(/\r\n/g,"</p><p>");
          this.questionRecord.children[0].myAnswer = this.questionRecord.myAnswer.replace(/\n/g,"</p><p>");
          console.log(this.questionRecord.children[0].myAnswer)
        }
        else{
          for(let i=0;i<this.questionRecord.children.length;i++){
            this.questionRecord.children[i].myAnswer = "<p>"+this.questionRecord.children[i].myAnswer;
            this.questionRecord.children[i].myAnswer = this.questionRecord.children[i].myAnswer.replaceAll(" ",""); //手工敲的空格取消
            this.questionRecord.children[i].myAnswer = this.questionRecord.children[i].myAnswer.replace(/\r\n/g,"</p><p>");
            this.questionRecord.children[i].myAnswer = this.questionRecord.children[i].myAnswer.replace(/\n/g,"</p><p>");
            console.log(this.questionRecord.children[i].myAnswer)
          }
        }
      });
    },
  }
}
</script>

<style scoped>
h5 {
  font-size: 14px;
  color: #333;
}
p {
  font-size: 14px;
  color: #333;
  line-height: 25px;
}
.word-full {
  color: #3794fe;
}
.word-similar {
  color: #ffa533;
}
.word-zero{
  color:red;
}
.score-full {
  margin: 0 5px;
  color: #fff;
  background: #3794fe;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  padding: 3px;
  border-radius: 8px;
}
.score-similar {
  margin: 0 5px;
  color: #fff;
  background: #ffa533;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  padding: 3px;
  border-radius: 8px;
}
</style>
