<template>
  <el-form v-show="questionRecord.id != null" style="clear:both;padding-top:20px" label-width="80px">
    <el-form-item label="题目ID">
      {{questionRecord.questionId}}
    </el-form-item>
    <el-form-item label="题型">
      {{questionRecord.questionTypeName}}
    </el-form-item>
    <el-form-item label="题干">
      {{questionRecord.stem}}
    </el-form-item>
    <el-form-item label="选项">
      <ul id="ulOptions">
        <div v-for="(option,i) in questionRecord.options">
          <li v-show="!option.right">{{option.content}}</li>
          <li v-show="option.right" style='color: dodgerblue'>{{option.content}}</li>
        </div>
      </ul>
    </el-form-item>
    <el-form-item label="学员回答">
      <ul id="ulMyOptions">
        <div v-for="(myOption,i) in questionRecord.myOptions">
          <li v-show="!myOption.result" style="color: orangered">{{myOption.content}}</li>
          <li v-show="myOption.result" style='color: dodgerblue'>{{myOption.content}}</li>
        </div>
      </ul>
    </el-form-item>
  </el-form>
</template>

<script>
import {UrlEnum} from "../../public/js/common-vue";

export default {
  name: "QuestionRecordKG",
  data(){
    return{
      questionRecord:{}
    }
  },
  methods:{
    initData(id){
      this.$http({
        method: "get",
        url: UrlEnum.STUDENT_QUESTION_RECORDS+"/"+id,
        data: '',
      })
          .then((res) => {
            let questionRecord = res.data;
            for(var i=0;i<questionRecord.myOptions.length;i++){
              for(let j=0; j< questionRecord.options.length; j++){
                if( questionRecord.options[j].id ==  questionRecord.myOptions[i].optionId){
                  questionRecord.myOptions[i].content =  questionRecord.options[j].content;
                }
              }
            }
            this.questionRecord=questionRecord;
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          })
    }
  }
}
</script>

<style scoped>
ul{
  float:left;
}
ul,li{
  list-style: none;
  margin:0px;
  padding: 0px;
}
</style>
